<template>
    <div class="h-100">
        <template v-if="categorias && categorias.length">
            <nota-principal :data="noticiaMasActual" v-if="noticiaMasActual"></nota-principal>
            <mini-notas class="col-sm-10 col-12 pb-5" :categorias="categorias"></mini-notas>
        </template>
        <template v-else>
            <div class="d-flex justify-content-center aling-items-center py-5">
                <p class="text-muted">No se encontraron noticias...</p>
            </div>
        </template>
    </div>
</template>
<script setup>
import NotaPrincipal from '@/components/public/template/NotaPrincipal'
import MiniNotas from '@/components/public/template/MiniNotas'
import BasicRequest from '@/utils/basic-request'
import { ref, inject, watch } from 'vue'
import { Delay } from '@/utils/delay'
import { useHead } from '@vueuse/head'
/* vars */

const categorias = ref({});
const noticiaMasActual = ref({});
const search = inject('search')
const loadingButton = inject('loadingButton')

/* functions */
const getNotas = async () =>{
    await BasicRequest.getData('get-notas', {params: {searchParam: search.value, paginate:50}})
    .then( e => {
        categorias.value = e.categorias.filter((gg)=> gg.notas.length > 0)
        let agrupaNoticias = []
        e.categorias.map((notas)=>{
            notas.notas.map((nota)=>{
                agrupaNoticias.push(nota)
            })
        })
        noticiaMasActual.value = agrupaNoticias.reduce((prev, current) => (prev.updated_at > current.updated_at) ? prev : current, []);
    })
    let metaTagImagenRuta = require('@/assets/logo.png');
    useHead({
        title: 'Noticias | María Auxiliadora',
        meta: [
            {
            name: `description`,
            content: `Descubre las últimas noticias y eventos del Instituto Privado María Auxiliadora, 
            una institución educativa comprometida con la excelencia académica y la formación integral de sus estudiantes. 
            Desde educación inicial hasta secundaria, nuestro equipo de educadores altamente capacitados trabaja para brindar una educación de calidad en un ambiente seguro y acogedor.`,
            },
            {
            name: `keywords`,
            content: 'Instituto María Auxiliadora, educación, formación integral, excelencia académica, educación inicial, educación secundaria, educación privada, educación de calidad, eventos educativos, noticias educativas.',
            },
            {
            name: `og:title`,
            content: 'Noticias María Auxiliadora',
            },
            {
            name: `og:image`,
            content: metaTagImagenRuta,
            },
            {
            name: `og:description`,
            content: `Descubre las últimas noticias y eventos del Instituto Privado María Auxiliadora, 
            una institución educativa comprometida con la excelencia académica y la formación integral de sus estudiantes. 
            Desde educación inicial hasta secundaria, nuestro equipo de educadores altamente capacitados trabaja para brindar una educación de calidad en un ambiente seguro y acogedor.`,
            },
        ],
            
    })
}
getNotas()

watch(search, async ()=>{
    loadingButton.value = true;
    await Delay.awaitFunction(1000)
    getNotas().then(()=>{
        loadingButton.value = false;
    })
})



</script>
